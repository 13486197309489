import React, { useState, useCallback } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import s from './ImageSlider.module.scss';

type ImageProps = {
  desktopImage: string;
  mobileImage: string;
  brandName: string;
};

const Image = ({ desktopImage, mobileImage, brandName }: ImageProps) => {
  const [isZoomed, setIsZoomed] = useState<boolean>(false);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (shouldZoom) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=2.0, user-scalable=yes'
      );
    }
    if (!shouldZoom) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      );
    }
  }, []);
  return (
    <ControlledZoom
      isZoomed={isZoomed}
      onClick={() => {
        alert('cliced');
      }}
      onZoomChange={handleZoomChange}
    >
      <picture className={s.imageSlider__wrapper}>
        <source srcSet={desktopImage} media="(min-width: 600px)" />
        <source srcSet={mobileImage} media="(max-width: 599px)" />
        <img src={desktopImage} alt={brandName} width={400} height={400} style={{ pointerEvents: 'auto' }} />
      </picture>
    </ControlledZoom>
  );
};

export default Image;
