import { combineReducers, configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartState/slice';
import wpdataReducer from './wpdataState/slice';
import productsdataReducer from './productsState/slice';

const rootReducer = combineReducers({
  cart: cartReducer,
  wpdata: wpdataReducer,
  productsdata: productsdataReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
