import React, { useState, useEffect } from 'react';
import s from './QuantityCards.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { productType } from 'lib/productsState/productsTypes';
import VariantsPicker from './VariantPickers/VariantPicker';
import StockCountdown from './StockCountdown';

interface productCard extends productType {
  active: boolean;
  id: string;
  colorVariants: boolean;
  sizeVariants: boolean;
  is_label_enabled: boolean;
  handleClick: (id: string) => void;
}

const QuantityCard = ({
  colorVariants,
  sizeVariants,
  active,
  amount,
  price,
  old_price,
  status,
  display_name,
  tr_additional_texts,
  defaultVariant,
  image,
  id,
  is_label_enabled,
  handleClick,
}: productCard) => {
  const { Settings, hero, CleanSettings } = usewpdataSelectors();
  const [formattedPrice, setFormattedPrice] = useState<string | null>(null);
  const [formattedOldPrice, setFormattedOldPrice] = useState<string | null>(null);
  useEffect(() => {
    setFormattedPrice(Settings.formattedString.replace(/(0\.00|0,00|0)/, price.toString()));
    setFormattedOldPrice(Settings.formattedString.replace(/(0\.00|0,00|0)/, old_price.toString()));
  }, [price, old_price]);

  return (
    <div
      style={{
        '--badge-best-seller': hero.styles.bestSellerColor,
        '--badge-best-value': hero.styles.bestValueColor,
      }}
      className={` ${s.card} ${active ? `${s['card--active']}` : ''}`}
      key={amount}
      onClick={() => {
        handleClick(id);
      }}
    >
      {status && is_label_enabled && !CleanSettings.cleanMode && (
        <div
          className={`${s['badge--mobile']} ${
            status.toLowerCase() === 'best seller' ? s['badge--mobile--seller'] : ''
          } ${status.toLowerCase() === 'best value' ? s['badge--mobile--value'] : ''}`.trim()}
        >
          {status}
        </div>
      )}
      <label htmlFor={String(amount)} className={`${s.card__content} ${active ? `${s['card--selected']}` : ''}`}>
        <div className={s.card__leftSide}>
          <input className={s.card__input} type="radio" name={String(id)} value={id} checked={active} id={id} />
          <div className={s.card__customRadioBtn}>
            <div className={s.card__customInnerRabioBtn}></div>
          </div>
          <img src={image} className={s.card__img} alt={Settings.brandName} />
          <div className={s.card__titleBox}>
            <div className={s.title} style={{ color: hero.styles.textColorPrimary }}>
              {display_name}
            </div>
            {tr_additional_texts && Settings.bundleMode && <StockCountdown text={tr_additional_texts} />}
            {tr_additional_texts && !Settings.bundleMode && (
              <div className={s.description} style={{ color: hero.styles.textColorPrimary }}>
                {tr_additional_texts}
              </div>
            )}
          </div>
        </div>
        <div className={s.card__rightSide}>
          <div className={s.card__price}>
            {status && !CleanSettings.cleanMode && (
              <div
                style={{
                  '--badge-best-seller': hero.styles.bestSellerColor,
                  '--badge-best-value': hero.styles.bestValueColor,
                  marginRight: !Settings.disablePrices ? '0' : '16px',
                }}
                className={`${s.badge} ${status.toLowerCase() === 'best seller' && s['badge--seller']} ${
                  status.toLowerCase() === 'best value' && s['badge--value']
                }`.trim()}
              >
                {status}
              </div>
            )}
            {Settings.disablePrices && (
              <>
                {Number(price) !== 0 ? (
                  <>
                    <span style={{ color: hero.styles.textColorPrimary }}>{formattedPrice && formattedPrice}</span>
                    <p className={s.card__eachText} style={{ color: hero.styles.textColorPrimary }}>
                      /{hero.each_label}
                    </p>
                  </>
                ) : (
                  <div style={{ color: hero.styles.textColorPrimary }}>{hero.free_label}</div>
                )}
              </>
            )}
          </div>
          {Settings.disablePrices && (
            <div className={s.discount} style={{ color: hero.styles.textColorSecondary }}>
              {formattedOldPrice && formattedOldPrice}
            </div>
          )}
        </div>
      </label>
      {active && (sizeVariants || colorVariants) && (
        <>
          <VariantsPicker numberOfItems={Number(amount)} defaultVariant={defaultVariant} id={id} />
        </>
      )}
    </div>
  );
};

export default QuantityCard;
