import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initializeVariantPickerState from '../../utils/defaultVariants';
import { FetchedProductType } from 'utils/types/productTypes';
import { cartProductItem } from './types';
import { productType, upsellItem, VariantType } from 'lib/productsState/productsTypes';

type CartState = {
  loader: boolean;
  isCartOpen: boolean;
  finalPrice: number;
  cartProducts: {
    mainProduct: {
      product: productType;
      variants: VariantType[];
      bundle: any[];
    };
    upsells: upsellItem[];
  };

  productsQuantity: number;
  withShippingProtection: boolean;
  totalSavings: number;
  subTotal: number;
  selectedProduct: FetchedProductType | null;
  selectedProductIsSubscribed: boolean;
  selectedProductFrequency: number;
  selectedProductQuantity: number;
  hasFreeGift: boolean;
  hasFreeShipping: boolean;
  freeGiftPrice: number;
  freeShippingPrice: number;
  upsellModal: any;
};

const initialState: CartState = {
  upsellModal: undefined,
  isCartOpen: false,
  finalPrice: 0,
  loader: false,
  subTotal: 0,
  totalSavings: 0,
  cartProducts: {
    mainProduct: {
      product: {} as any,
      variants: [],
      bundle: [],
    },
    upsells: [],
  },
  productsQuantity: 0,
  withShippingProtection: true,
  selectedProduct: null,
  selectedProductIsSubscribed: true,
  selectedProductFrequency: 1,
  selectedProductQuantity: 1,
  hasFreeGift: false,
  hasFreeShipping: false,
  freeShippingPrice: 100,
  freeGiftPrice: 100,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    toggleCart: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
    setLoader: (state) => {
      state.loader = !state.loader;
    },
    setUpsellModal: (state, action: PayloadAction<upsellItem | undefined>) => {
      state.upsellModal = action.payload;
    },
    addUpsellToCart: (state, action: PayloadAction<cartProductItem>) => {
      if (action.payload) {
        const sameSKUProducts = state.cartProducts.upsells.filter((upsell) => {
          return upsell.product.id === action.payload.product.id;
        });
        const matchingProduct = sameSKUProducts.find((upsell) => {
          return upsell.product.id === action.payload.product.id;
        });
        if (matchingProduct && action.payload.variants !== null) {
          const mappedUpsell = state.cartProducts.upsells.map((item) => {
            if (item.product.id === action.payload.product.id) {
              return {
                product: {
                  ...item.product,
                  amount: Number(item.product.amount) + 1,
                },
                variants: [...(Array.isArray(item.variants) ? item.variants : []), action.payload.variants],
              };
            }
            return item;
          });
          state.cartProducts.upsells = mappedUpsell;
        } else if (matchingProduct) {
          matchingProduct.product.amount++;
        } else {
          state.cartProducts.upsells = [
            ...state.cartProducts.upsells,
            {
              product: action.payload.product,
              variants: [action.payload.variants],
              bundle: action.payload.bundle ? action.payload.bundle : [],
            },
          ];
        }
      }
    },

    removeVariantMainProduct: (state, action: PayloadAction<{ variantId: string }>) => {
      const newVariantsArray = state.cartProducts.mainProduct.variants.filter((variant) => {
        return variant.id !== action.payload.variantId;
      });
      if (newVariantsArray.length > 0) {
        state.cartProducts.mainProduct = {
          ...state.cartProducts.mainProduct,
          product: {
            ...state.cartProducts.mainProduct.product,
            amount: newVariantsArray.length,
          },
          variants: newVariantsArray,
        };
      }
    },

    removeVariantUpsell: (state, action: PayloadAction<{ variantId: string; upsellId: string }>) => {
      const indexOfUpsell = state.cartProducts.upsells.findIndex((item) => {
        return item.product.id === action.payload.upsellId;
      });
      const newUpsellObject = state.cartProducts.upsells[indexOfUpsell].variants.filter((variant) => {
        return variant.id !== action.payload.variantId;
      });

      state.cartProducts.upsells[indexOfUpsell] = {
        ...state.cartProducts.upsells[indexOfUpsell],
        product: {
          ...state.cartProducts.upsells[indexOfUpsell].product,
          amount: newUpsellObject.length,
        },
        variants: newUpsellObject,
      };
    },

    addBundleUpsellToCart: (state, action: PayloadAction<{ upsell: any }>) => {
      state.cartProducts.upsells = [...state.cartProducts.upsells, action.payload.upsell];
    },

    setSubtotalPrice: (state, action: PayloadAction<{ subTotal: number }>) => {
      state.subTotal = action.payload.subTotal;
    },

    adjustUpsellQuantity: (state, action: PayloadAction<{ id: string; newValue: number; variantId: number }>) => {
      const upsellProductIndex = state.cartProducts.upsells.findIndex(
        (upsell) => upsell.product.id === action.payload.id
      );

      if (upsellProductIndex !== -1) {
        const upsellProduct = state.cartProducts.upsells[upsellProductIndex];
        let updatedVariants = upsellProduct.variants ? [...upsellProduct.variants] : null;

        if (action.payload.newValue < upsellProduct.product?.amount && action.payload.variantId !== null) {
          const indexToRemove = updatedVariants.findIndex((variant) => variant.id === action.payload.variantId);
          if (indexToRemove !== -1) {
            updatedVariants.splice(indexToRemove, 1);
          }
        }
        if (action.payload.newValue > upsellProduct.product?.amount && action.payload.variantId !== null) {
          const variantToAdd = upsellProduct.variants.find((variant) => variant.id === action.payload.variantId);
          if (variantToAdd) {
            updatedVariants.push(variantToAdd);
          }
        }

        state.cartProducts.upsells[upsellProductIndex] = {
          ...upsellProduct,
          product: {
            ...upsellProduct.product,
            amount: action.payload.newValue,
          },
          variants: updatedVariants,
        };
      }
    },
    decrementMainProductQty: (state, action: PayloadAction<{ newProductObject: any; variantIndex: string }>) => {
      if (action.payload.variantIndex) {
        let updatedVariants = [...state.cartProducts.mainProduct.variants];
        const indexToRemove = updatedVariants.findIndex((variant) => variant.id === action.payload.variantIndex);
        if (indexToRemove !== -1) {
          updatedVariants.splice(indexToRemove, 1);
        }
        state.cartProducts.mainProduct = {
          ...state.cartProducts.mainProduct,
          product: action.payload.newProductObject,
          variants: updatedVariants,
        };
      } else {
        state.cartProducts.mainProduct.product.amount = action.payload.newProductObject;
      }
    },
    incrementMainProductQty: (state, action: PayloadAction<{ newProductObject: any; variantIndex: string }>) => {
      if (action.payload.variantIndex) {
        let updatedVariants = [...state.cartProducts.mainProduct.variants];
        const variantToAdd = state.cartProducts.mainProduct.variants.find(
          (variant) => variant?.id === action.payload.variantIndex
        );
        if (variantToAdd) {
          updatedVariants.push(variantToAdd);
        }
        state.cartProducts.mainProduct = {
          ...state.cartProducts.mainProduct,
          product: action.payload.newProductObject,
          variants: updatedVariants,
        };
      } else {
        state.cartProducts.mainProduct.product.amount = action.payload.newProductObject;
      }
    },

    adjustMainProductQty: (state, action: PayloadAction<{ newValue: number | any; variantId: string }>) => {
      if (state.cartProducts?.mainProduct?.product) {
        if (action.payload.variantId) {
          let updatedVariants = [...state.cartProducts.mainProduct.variants];
          if (action?.payload?.newValue?.amount < state.cartProducts.mainProduct?.product?.amount) {
            const indexToRemove = updatedVariants.findIndex((variant) => variant.id === action.payload.variantId);
            if (indexToRemove !== -1) {
              updatedVariants.splice(indexToRemove, 1);
            }
          } else {
            const variantToAdd = state.cartProducts.mainProduct.variants.find(
              (variant) => variant.id === action.payload.variantId
            );
            if (variantToAdd) {
              updatedVariants.push(variantToAdd);
            }
          }

          state.cartProducts.mainProduct = {
            product: action.payload.newValue,
            variants: updatedVariants,
          };
        } else {
          state.cartProducts.mainProduct.product.amount = action.payload.newValue;
        }
      }
    },
    toggleProtection: (state) => {
      state.withShippingProtection = !state.withShippingProtection;
    },
    setTotalSavings: (state) => {
      let savings = 0;
      state.cartProducts?.map((product: any) => (savings += product.savings || 0));
      state.totalSavings = savings || 0;
    },
    setFinalPrice: (state, action: PayloadAction<{ total: number; savings: number }>) => {
      state.finalPrice = action.payload.total;
      state.totalSavings = action.payload.savings;
    },
    setBundleUpsell: (state, action: PayloadAction<{ total: number; savings: number }>) => {
      state.finalPrice = action.payload.total;
      state.totalSavings = action.payload.savings;
    },
    setProductsQuantity: (state) => {
      let quantity = 0;
      state.cartProducts.upsells?.map((product) => (quantity += Number(product.product.amount)));
      quantity += Number(state.cartProducts.mainProduct.product.amount);
      state.productsQuantity = quantity;
    },
    setSavingsQuantityPrice: (state) => {
      cartSlice.caseReducers.setTotalSavings(state);
      cartSlice.caseReducers.setFinalPrice(state);
      cartSlice.caseReducers.setProductsQuantity(state);
    },
    setCartProducts: (state, action: PayloadAction<any[]>) => {
      state.cartProducts = action.payload;
    },
    addCartProduct: (state, action: PayloadAction<any>) => {
      state.cartProducts?.push(action.payload);
    },
    updateCartProduct: (state: CartState, action: PayloadAction<{ id: number; changes: Partial<any> }>) => {
      const index = state.cartProducts?.findIndex((product: any) => product.data.id === action.payload.id);
      if (index !== -1 && index !== undefined && state.cartProducts !== undefined) {
        state.cartProducts[index] = {
          ...state.cartProducts[index],
          ...action.payload.changes,
        };
      }
    },
    deleteCartProduct: (state, action: PayloadAction<number>) => {
      state.cartProducts = state.cartProducts?.filter((product: any) => product.data.id !== action.payload);
    },
    setSelectedProduct: (state, action: PayloadAction<{ product: ProductType; variant: any }>) => {
      if (state.cartProducts && state.cartProducts.mainProduct) {
        const currentAmount = state.cartProducts.mainProduct.product.amount;
        const newAmount = action.payload.product.amount ?? action.payload.product[0].amount;
        const difference = Number(newAmount) - Number(currentAmount);
        if (difference > 0 && action.payload.variant) {
          const newVariants = Array(difference).fill(action.payload.variant);
          state.cartProducts.mainProduct.variants.push(...newVariants);
        } else if (difference < 0) {
          const variantsToRemove = Math.abs(difference);
          const currentVariants = state.cartProducts.mainProduct.variants;
          if (currentVariants.length >= variantsToRemove) {
            state.cartProducts.mainProduct.variants = currentVariants.slice(
              0,
              currentVariants.length - variantsToRemove
            );
          }
        }
        state.cartProducts.mainProduct.product = action.payload.product;
      }
    },
    setSelectedProductIsSubscribed: (state, action: PayloadAction<boolean>) => {
      state.selectedProductIsSubscribed = action.payload;
    },
    setSelectedProductFrequency: (state, action: PayloadAction<number>) => {
      state.selectedProductFrequency = action.payload;
    },
    setSelectedProductQuantity: (state, action: PayloadAction<number>) => {
      state.selectedProductQuantity = action.payload;
    },
    deleteSelectedProduct: (state) => {
      state.selectedProduct = null;
    },
    setDefaultVariants: (state, action: PayloadAction<{ defaultVariant: any; maxAmount: any }>) => {
      const initialVariants = initializeVariantPickerState(
        action.payload.colorVariants,
        action.payload.sizeVariants,
        Number(state?.cartProducts?.mainProduct?.product?.amount)
      );
      if (!state.cartProducts) return;
      state.cartProducts.mainProduct.variants = initialVariants;
    },
    changeVariantColorState: (
      state,
      action: PayloadAction<{
        type: string;
        value: string;
        id: string;
        label: string;
        img: string;
        extendedLabel: string;
      }>
    ) => {
      const { id, value, label, img, extendedLabel } = action.payload;

      if (state.cartProducts?.mainProduct?.variants && Array.isArray(state.cartProducts.mainProduct.variants)) {
        const index = Number(id);

        if (index >= 0 && index < state.cartProducts.mainProduct.variants.length) {
          const updatedVariants = [...state.cartProducts.mainProduct.variants];
          updatedVariants[index] = {
            ...updatedVariants[index],
            id: `${state.cartProducts.mainProduct.variants[index].size}+${action.payload.value}`,
            color: value,
            colorLabel: label,
            colorImage: img,
            extendedColorLabel: extendedLabel,
          };
          state.cartProducts.mainProduct.variants = updatedVariants;
        }
      }
    },
    setSelectedUpsell: (state, action: PayloadAction<{ upsellItem: any; upsellId: string }>) => {
      state.cartProducts = {
        ...state.cartProducts,
        upsells: [action.payload],
      };
    },
    changeVariantSizeState: (
      state,
      action: PayloadAction<{ type: string; value: string; id: string; label: string; extendedLabel: string }>
    ) => {
      state.cartProducts?.mainProduct?.variants.forEach((item: variantObjectType, index: number) => {
        if (index === action.payload.id && state.cartProducts) {
          state.cartProducts.mainProduct.variants[index] = {
            ...item,
            id: `${action.payload.value}+${state.cartProducts.mainProduct.variants[index].color}`,
            size: action.payload.value,
            sizeLabel: action.payload.label,
            extendedSizeLabel: action.payload.extendedLabel,
          };
        }
      });
    },
    setUpsells: (state, action: PayloadAction<any[]>) => {
      state.cartProducts = {
        ...state.cartProducts,
        upsells: action.payload,
      };
    },
    setDefaultBundle: (
      state,
      action: PayloadAction<{
        value: string;
        id: string;
        parent_id: string;
        label: string;
        price: number;
        old_price: number;
        konnektive_id: string;
      }>
    ) => {
      const bundlePropertyExists = state.cartProducts?.mainProduct?.bundle.some((item) => {
        return item.id === action.payload.id;
      });
      if (!bundlePropertyExists) {
        return {
          ...state,
          cartProducts: {
            ...state.cartProducts,
            mainProduct: {
              ...state?.cartProducts?.mainProduct,
              bundle: [
                ...state?.cartProducts?.mainProduct?.bundle,
                {
                  parent_id: action.payload.parent_id,
                  sku: action.payload.sku,
                  label: action.payload.label,
                  id: action.payload.id,
                  price: action.payload.price,
                  old_price: action.payload.old_price,
                  konnektive_id: action.payload.konnektive_id,
                },
              ],
            },
          },
        };
      }
    },
    setBundleProduct: (
      state,
      action: PayloadAction<{
        sku: string;
        id: string;
        parent_id: string;
        label: string;
        price: number;
        old_price: number;
        konnektive_id: string;
      }>
    ) => {
      const filteredBundle = state?.cartProducts?.mainProduct?.bundle?.filter(
        (item) => item.parent_id !== action.payload.parent_id
      );
      const updatedBundleItem = {
        sku: action.payload.sku,
        label: action.payload.label,
        id: action.payload.id,
        parent_id: action.payload.parent_id,
        price: action.payload.price,
        old_price: action.payload.old_price,
        konnektive_id: action.payload.konnektive_id,
      };

      return {
        ...state,
        cartProducts: {
          ...state.cartProducts,
          mainProduct: {
            ...state?.cartProducts?.mainProduct,
            bundle: [...filteredBundle, updatedBundleItem],
          },
        },
      };
    },
  },
});

export const {
  setLoader,
  toggleCart,
  setFinalPrice,
  setUpsellModal,
  addUpsellToCart,
  setCartProducts,
  addCartProduct,
  setProductsQuantity,
  toggleProtection,
  deleteCartProduct,
  setTotalSavings,
  setSelectedProductIsSubscribed,
  setSelectedProductFrequency,
  setSelectedProductQuantity,
  deleteSelectedProduct,
  setDefaultVariants,
  setSavingsQuantityPrice,
  changeVariantColorState,
  changeVariantSizeState,
  setSelectedUpsell,
  setSelectedProduct,
  setUpsells,
  adjustMainProductQty,
  addBundleUpsellToCart,
  decrementMainProductQty,
  incrementMainProductQty,
  setDefaultBundle,
  setSubtotalPrice,
  setBundleProduct,
  adjustUpsellQuantity,
  removeVariantUpsell,
  removeVariantMainProduct,
} = cartSlice.actions;

export default cartSlice.reducer;
