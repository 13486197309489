import React, { Suspense, useEffect, useState } from 'react';

// Lazy load the Cart component
const Cart = React.lazy(() => import('./Cart'));

const LazyCart = ({ isCartOpen }) => {
  const [shouldLoadCart, setShouldLoadCart] = useState(false);

  useEffect(() => {
    if (isCartOpen && !shouldLoadCart) {
      setShouldLoadCart(true);
    }
  }, [isCartOpen, shouldLoadCart]);

  return (
    <div>
      {shouldLoadCart && (
        <Suspense fallback={<div>Loading Cart...</div>}>
          <Cart />
        </Suspense>
      )}
    </div>
  );
};

export default LazyCart;
