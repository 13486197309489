import s from './ContentBlock.module.scss';
import Checkmark from '../Icons/BlackCheckmark';
import IgVideos from '../IgVideos/IgVideos';
import RatingRow from './RatingRow';
import ItemsLeft from '../ItemsLeftBar/ItemsLeftBar';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useproductsdataSelectors } from 'lib/productsState/selectors';
import Divider from './Divider';

import SizeChart from './SizeChart';

const ContentBlock = () => {
  const { hero } = usewpdataSelectors();
  const { EnableSizeVariants } = useproductsdataSelectors();

  return (
    <>
      <div className={s.contentBlockDivider}></div>
      <div className={s.contentBlock}>
        <p className={s.subtitle} style={{ color: hero.styles.textColorPrimary }}>
          {hero.subtitle}
        </p>
        <div className={s.benefits}>
          {hero?.checklist &&
            hero?.checklist.map((benefit, index) => (
              <div className={s.item} key={index}>
                <Checkmark fill={hero.styles.textColorSecondary} />
                <p style={{ color: hero.styles.textColorSecondary }}>{benefit.tr_checklistItem}</p>
              </div>
            ))}
        </div>
        <div className={s.testimonialsRow}>
          {hero?.show_socials && (
            <>
              <IgVideos />
              <RatingRow />
            </>
          )}
          {EnableSizeVariants && <SizeChart />}
        </div>
        <div style={{ margin: '12px 0' }}>
          <Divider text={hero.divider_text} />
        </div>
        {hero?.show_stockLeft && <ItemsLeft />}
      </div>
    </>
  );
};

export default ContentBlock;
