import React, { Suspense } from 'react';
import { useInView } from 'react-intersection-observer';

const LazyComponent = ({ Component }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <div ref={ref} style={{ minHeight: inView ? 0 : '200px', opacity: inView ? 1 : 0 }}>
      {inView && (
        <Suspense fallback={<div style={{ height: '200px', width: '100%' }}></div>}>
          <Component />
        </Suspense>
      )}
    </div>
  );
};

export default LazyComponent;
