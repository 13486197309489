import React from 'react';
import s from './Divider.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';

type DividerTypes = {
  text: string;
  addMargin: boolean;
};
const Divider = ({ text, addMargin }: DividerTypes) => {
  const { hero } = usewpdataSelectors();

  return (
    <div className={s.divider} style={{ marginBottom: addMargin ? '12px' : '0px' }}>
      <div></div>
      <p style={{ color: hero.styles.textColorPrimary }}>{text}</p>
      <div></div>
    </div>
  );
};

export default Divider;
