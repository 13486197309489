import { VariantType, variantObjectType } from '../lib/productsState/productsTypes';
function initializeVariantPickerState(
  colorVariants: VariantType[],
  sizeVariants: VariantType[],
  maxAmount: number
): variantObjectType[] | void {
  const [defaultColorVariant] =
    (colorVariants && colorVariants?.filter((variant: VariantType) => variant.default_variant === true)) || [];
  const [defaultSizeVariant] =
    (sizeVariants && sizeVariants?.filter((variant: VariantType) => variant.default_variant === true)) || [];

  let defaultVariants;
  if (defaultColorVariant || defaultSizeVariant) {
    defaultVariants = {
      color: defaultColorVariant?.value,
      colorLabel: defaultColorVariant?.tr_label,
      size: defaultSizeVariant?.value,
      sizeLabel: defaultSizeVariant?.tr_label,
      extendedSizeLabel: defaultSizeVariant?.tr_additional_label,
      colorImage: defaultColorVariant.variant_image,
      extendedColorLabel: defaultColorVariant?.tr_additional_label,
    };
  }

  if (defaultColorVariant || defaultSizeVariant) {
    return Array(maxAmount)
      .fill(null)
      .map((_) => {
        return {
          id: `${defaultVariants.size}+${defaultVariants?.color}`,
          color: defaultVariants?.color,
          colorLabel: defaultVariants?.colorLabel,
          extendedSizeLabel: defaultVariants?.extendedSizeLabel,
          extendedColorLabel: defaultVariants?.extendedColorLabel,
          size: defaultVariants?.size,
          sizeLabel: defaultVariants?.sizeLabel,
          colorImage: defaultVariants?.colorImage,
        };
      });
  }
  return;
}

export default initializeVariantPickerState;
