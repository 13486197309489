type MenuBurgerProps = {
  className?: string;
  handleMenuToggle: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
};

export const MenuBurger = ({ className, handleMenuToggle, isOpen }: MenuBurgerProps) => (
  <svg
    onClick={() => handleMenuToggle(true)}
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 6H21M3 12H21M3 18H21" stroke="#3A1F15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
